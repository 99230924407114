<template>
  <div>
    <!-- <object
      data="@/assets/img/test.pdf"
      type="application/pdf"
      width="100%"
      style="height: 83vh"
    /> -->
    <!-- <p style="font-size: 1rem">
      Click <a :href="`${url_link}`"><b>HERE</b></a> to view download and view
      your full Report
    </p> -->
    <div v-if="!fileId">Processing...</div>
    <div v-else-if="isUploading">
      This report is uploading in background, Please wait (DO NOT REFRESH)
    </div>
    <div id="pdf-viewer" width="100%" style="height: 83vh"></div>
    <!-- <embed :src="filesurl" width="100%" style="height: 83vh" /> -->
    <!-- <iframe
      id="pdfviewer"
      src="http://docs.google.com/gview?embedded=true&url=filesurl&amp;embedded=true"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe> -->
    <!-- <vue-pdf-app
      style="height: 100vh"
      :pdf="pdf"
      :config="config"
    ></vue-pdf-app> -->

    <!-- <VuePdfApp pdf="@/assets/img/test.pdf"></VuePdfApp> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
// import "vue-pdf-app/dist/icons/main.css";
import PDFObject from "pdfobject";
export default {
  name: "ReportViewer",
  components: {
    // VuePdfApp: VuePdfApp,
  },
  data() {
    return {
      // url_link:
      //   "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      config: {
        toolbar: {
          toolbarViewerLeft: { findbar: false },
        },
      },
      pdf: this.loadFiles(),
      filesurl: "",
      fileId: "",
    };
  },
  mounted: function () {
    // this.viewPDF();
  },
  watch: {
    isUploading: function () {
      this.loadFiles();
    },
  },
  computed: {
    ...mapState({
      uploadingFiles: (state) => state.files.uploadingFiles,
    }),
    isUploading() {
      if (
        this.uploadingFiles.find(
          (x) => x.id == this.fileId && x.category == "REPORT"
        )
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions("files", ["getFilesbycategory"]),
    loadFiles() {
      if (!this.isUploading) {
        const filesnew = {
          feature: "Reports",
          featureId: this.$route.params.id,
          category: "REPORT",
        };
        this.getFilesbycategory(filesnew).then((res) => {
          this.filesurl = res[0].links[0].href;
          this.fileId = res[0].id;
          if (!this.isUploading)
            PDFObject.embed(this.filesurl, "#pdf-viewer", { forcePDFJS: true });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>

/////feature: "Reports", featureId: this.$route.params.id
